import React, { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ColorThief from "colorthief";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PlaceholderImage from "./assets/sunset.jpg";
import "./App.css";

function App() {
	const previewImageRef = useRef();

	const colorThief = new ColorThief();
	const colorThiefQuality = 5;

	const [colors, setColors] = useState([]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			"image/*": [],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			acceptedFiles.forEach((currentFile) => {
				previewImageRef.current.src = URL.createObjectURL(currentFile);
			});
		},
	});

	function imageLoaded() {
		setColors([]);
		setColors(
			colorThief.getPalette(previewImageRef.current, colorThiefQuality)
		);
		URL.revokeObjectURL(previewImageRef.current.src);
	}

	function copyColor(e) {
		navigator.clipboard.writeText(e.target.innerText);
		toast("🎨 " + e.target.innerText + " copied to clipboard!", {
			position: "bottom-center",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			transition: Flip,
		});
	}

	const rgbToHex = (r, g, b) =>
		"#" +
		[r, g, b]
			.map((x) => {
				const hex = x.toString(16);
				return hex.length === 1 ? "0" + hex : hex;
			})
			.join("");

	const paletteColorsList = colors.map((color) => (
		<div
			key={"rgb(" + color[0] + "," + color[1] + ", " + color[2] + ")"}
			className="palette-color"
			style={{
				backgroundColor:
					"rgb(" + color[0] + "," + color[1] + ", " + color[2] + ")",
			}}
		>
			<div className="color-overlay">
				<span className="color-hex" onClick={copyColor}>
					{rgbToHex(color[0], color[1], color[2])}
				</span>
				<span className="color-rgb" onClick={copyColor}>
					rgb({color[0] + ", " + color[1] + ", " + color[2]})
				</span>
			</div>
		</div>
	));

	return (
		<div className="container">
			<header className="header">
				<h1>
					Extract{" "}
					<span className="bold-highlight multicolor-highlight">
						color palette
					</span>{" "}
					from image
				</h1>
			</header>

			<section>
				<div {...getRootProps({ className: "dropzone" })}>
					<input {...getInputProps()} />
					{isDragActive ? (
						<p>Drop the files here...</p>
					) : (
						<p>
							Drag and drop an image here, or click to upload a
							file
						</p>
					)}
				</div>
				<div className="preview-container" {...getRootProps()}>
					<img
						src={PlaceholderImage}
						ref={previewImageRef}
						className="preview-img"
						id="preview-img"
						alt="Placeholder example displaying a sunset"
						onLoad={imageLoaded}
					/>
				</div>
				<div className="palette-container">{paletteColorsList}</div>
			</section>

			<footer className="footer">
				<Popup
					trigger={
						<button className="privacy-policy-btn">
							Privacy Policy
						</button>
					}
					className="privacy-policy-container"
					position="center center"
					modal="true"
				>
					<div>
						<PrivacyPolicy />
					</div>
				</Popup>
			</footer>

			<ToastContainer />
		</div>
	);
}

export default App;
